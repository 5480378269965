.registerLink {
  float: right;
}

.profilePopup {
  color: grey;
}

.profilePopup:hover {
  color: black;
  cursor: pointer;
}
