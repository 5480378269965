.extension-container {
  display: flex;
}

.extension-checkbox {
  margin-right: 7px;
}

.evaluationCriteriaDiv {
  display: flex;
  width: 880px;
  margin-bottom: 10px;
}

.criterion {
  margin-right: 10px;
  width: 300px;
}

.weight {
  margin-right: 10px;
  width: 150px;
}

.addCriteriaFieldButtonDiv {
  margin: 10px 0px;
}
