.submissionListDiv {
    padding: 50px;
}

.submissionListDivMobile {
    padding-top: 40px;
}

.size {
    width: 20;
}