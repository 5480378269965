.conferenceListDiv {
  padding: 50px;
}

.conferenceListDivMobile {
  padding-top: 40px;
}

.loadingDiv {
  text-align: center;
  margin-top: 100px;
}
