.paginationElementDiv {
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 7px;
}

.trackSubmissionsBackButton {
  margin-top: 105px;
  margin-left: 7px;
  margin-bottom: 15px;
}

.assignYourselfLabel {
  font-style: italic;
}

.assignYourselfLabel:hover {
  text-decoration: underline;
  cursor: pointer;
}
