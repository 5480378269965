.conferenceTitle {
  font-weight: bold;
  font-size: 25px;
}

.conferenceMeta {
  font-size: 14px;
  color: grey;
}

.conferenceContent {
  margin-top: 15px;
  font-size: 16px;
}

.submitButton {
  width: 100%;
  margin-top: 3%;
}

.trackPanel {
  margin-top: 2%;
}

.size {
  width: 10;
}

.submissionRow {
  cursor: pointer;
}

.submissionRow:hover {
  background-color: lightgrey;
}
