.userListDiv {
  padding: 50px;
}

.userListDivMobile {
  padding-top: 40px;
}

.loadingDiv {
  text-align: center;
  margin-top: 100px;
}
