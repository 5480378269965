.pageSize {
  margin-bottom: 15px;
  /* margin-left: 7px; */
  margin-right: 7px;
  height: 42px;
}

.pageSizeComponentDiv {
  margin-top: 15px;
  margin-left: 7px;
}
