.registerForm {
  margin: 10%;
}

.formError {
  color: red;
}

.backButton {
  margin-top: 100px;
}
