.submissionTitle {
  font-weight: bold;
  font-size: 25px;
}

.submissionMeta {
  font-size: 14px;
  color: grey;
}

.submissionContent {
  margin-top: 15px;
  font-size: 16px;
}

.link {
  color: black;
  text-decoration: underline;
}

.download {
  text-align: center;
}

.withdrawButtonWrapper {
  margin-top: 10px;
}
