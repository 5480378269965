.conferenceTitle {
  font-weight: bold;
  font-size: 25px;
}

.conferenceMeta {
  font-size: 14px;
  color: grey;
}

.conferenceContent {
  margin-top: 15px;
  font-size: 16px;
}

.submitButton {
  width: 100%;
  margin-top: 3%;
}

.trackPanel {
  margin-top: 2%;
}

.size {
  width: 10;
}

.additionalInfoLabel {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Image hower: */
.logoImageContainer {
  text-align: center;
}
.image {
  opacity: 1;
  /* display: block; */
  /* width: 100%; */
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  background-color: white;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* text-align: center; */
}

.logoImageContainer:hover .image {
  opacity: 0.7;
}

.logoImageContainer:hover .middle {
  opacity: 1;
}
